import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ActiveStep } from '../../shared/enums/active-step';
import { ProductCategory } from '../../shared/enums/product-category';
import { TnTModule } from '../../shared/enums/tnt-module';
import { ItemsService } from '../services/items.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  languageInLowerCase;
  step;
  statusImageToShow;
  localTranslations;
  /* Commented as per Story DCS-11276 */
  // availableFromTimeBackup: string = '11:00';
  template2 = {
    productCategoryName: ['items', 'productCategory', 'translations', 'productCategory.$$.name'], // 'function',function (str){return str.toUpperCase()}],
    productCategoryTheName: ['items', 'productCategory', 'translations', 'productCategory.$$.theName'],
    productCategoryPronoun: ['items', 'productCategory', 'translations', 'productCategory.$$.pronoun'],
    productCategoryPronounCapitalized: ['items', 'productCategory', 'translations', 'productCategory.$$.pronoun',
      'function', this.toUpperCase],
    productCategoryNameCapitalized: ['items', 'productCategory', 'translations', 'productCategory.$$.name',
      'function', this.toUpperCase
    ],
    productCategoryTheNameCapitalized: ['items', 'productCategory'
      , 'translations', 'productCategory.$$.theName',
      'function', this.toUpperCase
    ],
    earliestAvailableDate: ['items', 'earliestAvailableTime.day', 'function', this.formatDate],
    earliestAvailableTime: ['items', 'earliestAvailableTime.day', 'function', this.formatDate, 'items', 'earliestAvailableTime.time'],
    latestAvailableDate: ['items', 'latestAvailableTime.day', 'function', this.formatDate, 'function', this.addDatePrepositionFrench],
    latestAvailableTime: ['items', 'latestAvailableTime.day', 'function', this.formatDate, 'items', 'latestAvailableTime.time'],
    expectedDeliveryDate: ['items', 'expectedDeliveryTimeRange.day', 'function', this.expectedDeliveryDate, 'function', this.addDatePreposition],
    expectedDeliveryDateCapitalized: ['items', 'expectedDeliveryTimeRange.day', 'function', this.expectedDeliveryDate, 'function', this.addDatePreposition,
      'function', this.toUpperCase],
    expectedDeliveryDate2: ['items', 'expectedDeliveryTimeRange', 'function', this.expectedDeliveryDateTimeRangeFun],
    expectedDeliveryDate2Capitalized: ['items', 'expectedDeliveryTimeRange.day2', 'function', this.expectedDeliveryDate, 'function', this.addDatePreposition,
      'function', this.toUpperCase],
    expectedDeliveryTime1: ['items', 'expectedDeliveryTimeRange.day', 'function', this.expectedDeliveryDate, 'items', 'expectedDeliveryTimeRange.time1'],
    expectedDeliveryTime2: ['items', 'expectedDeliveryTimeRange.day', 'function', this.expectedDeliveryDate, 'items', 'expectedDeliveryTimeRange.time2'],
    attemptedDeliveryDate: ['items', 'attemptedDeliveryTime.day', 'function', this.formatDate, 'function', this.addDatePreposition],
    attemptedDeliveryTime: ['items', 'attemptedDeliveryTime.time'],
    actualDeliveryDate: ['items', 'actualDeliveryTime.day', 'function', this.formatDate, 'function', this.addDatePreposition],
    actualDeliveryTime: ['items', 'actualDeliveryTime.day', 'function', this.formatDate, 'items', 'actualDeliveryTime.time'],
    prefLink: ['items', 'linkToDeliveryPreferences'],
    totalInNetworkHours: ['items', 'totalInNetworkTime.hours'],
    totalInNetworkMinutes: ['items', 'totalInNetworkTime.minutes'],
    nrOfStopsUntilTarget: ['items', 'itemOnRoundStatus.nrOfStopsUntilTarget'],
    countryOfDepurtueCode: ['items', 'countryOfDepurture', 'translations', 'countries.$$'],
    reason: ['items', 'reasonCode', 'function', this.translateReason],
    backToSenderReason: ['items', 'backToSenderReasonCode', 'translations', 'backToSenderReason.$$'],
    receiverCountry: ['items', 'receiver.countryCode', 'translations', 'countries.$$'],
    senderCommercialName: ['items', 'senderCommercialName'],
    webformUrl: ['items', 'webformUrl', 'function', this.formURL],
    notification: ['items', 'notification'],
    partnerName: ['items', 'partnerName'],
    partnerDeliveryTrackingUrl: ['items', 'partnerDeliveryTrackingUrl'],
    outboundLeadTime: ['items', 'outboundLeadTimeInformation', 'function', this.formLeadTimeText],
    outboundLeadTimesUrl: ['items', 'outboundLeadTimeInformation.showOutboundLeadTimesLink', 'function', this.showLeadTimesLink, 'translations', 'label.OutboundLeadTimesUrl'],
    inboundLeadTime: ['items', 'inboundLeadTimeInformation', 'function', this.formLeadTimeText],
    customsLeadTime: ['items', 'customsLeadTimeInformation', 'function', this.formLeadTimeText],
    customsLeadTimesUrl: ['items', 'customsLeadTimeInformation.showOutboundLeadTimesLink', 'function', this.showLeadTimesLink, 'translations', 'label.CustomsLeadTimesUrl'],
    codAmount: ['items', 'codAmount', 'function', this.currencify],
    dutyAmount: ['items', 'dutyAmount', 'function', this.currencify],
    customerReference: ['items', 'customerReference'],
    activeForDeliveryPreferences: ['items', 'activeForDeliveryPreferences'],
    services: ['items', 'services'],
    cost: ['items', 'cost'],
    showCallCenterCode: ['items', 'showCallCenterCode'],
    location: ['items', 'deliveryPoint.name', 'function', this.getLocation],
    isRegisteredMail: ['items', 'productCategory', 'function', this.isRegisteredMail],
    paymentDueDate: ['items', 'customs.AdditionalDetails.PaymentDueDate', 'function', this.formatDatewithYear, 'function', this.addDatePrepositionFrench],
    estimatedDateOfHandover: ['items', 'estimatedDateOfHandover', 'function', this.formatDatewithYear, 'function', this.addDatePrepositionFrench],
    pay6Triggered: ['items', 'pay6Triggered'],
    country: ['items', 'receiver.countryCode', 'translations', 'countries.$$'],
    sender: ['items', 'senderCommercialName.default', 'function', this.getSender],
    Sender: ['items', 'senderCommercialName.default', 'function', this.getthisSender],
    pickupPointType: ['items', 'deliveryPoint.type', 'translations', 'deliveryPointType.$$'],
    infoAboutShipmentURL: ['items', 'itemCode', 'function', this.infoAboutShipmentURL],
  };

  constructor(private router: Router, private itemsService: ItemsService, private readonly translate: TranslateService) { }

  getNeighbourDelivery(neighbourAddress) {
    console.log('called neighbourDelivery', neighbourAddress);
    let output = '';
    if (neighbourAddress.firstName) {
      output += neighbourAddress.firstName + ' ';
    }
    if (neighbourAddress.lastName) {
      output += neighbourAddress.lastName + ', ';
    }
    if (neighbourAddress.street) {
      output += neighbourAddress.street + ' ';
    }
    if (neighbourAddress.streetNumber) {
      output += neighbourAddress.streetNumber;
    }
    if (neighbourAddress.postboxNumber && neighbourAddress.street && neighbourAddress.streetNumber) {
      output += ' / ' + neighbourAddress.postboxNumber;
    }
    if (neighbourAddress.streetNumber) {
      output += ', ';
    }
    if (neighbourAddress.postalCode) {
      output += neighbourAddress.postalCode + ' ';
    }
    if (neighbourAddress.city) {
      output += neighbourAddress.city;
    }
    output = output.replace(/,\s*$/, '');
    output = output.toUpperCase();
    return output;
  }

  processItemsData(data, language, translations, module) { // Item loop Start
    this.localTranslations = translations;
    const items = data;
    let item;
    const tntModule = module;

    for (item of items) {
      if (item.processOverview.textKey) {
        if (item.processOverview.textKey[language.toUpperCase()]) {
          item.textKey = item.processOverview.textKey[language.toUpperCase()];
        } else {
          item.textKey = item.processOverview.textKey;
        }
        if (typeof (item.textKey) === 'object' && item.textKey !== null) {
          item.textKey = this.translateTextKey(item.textKey, item, translations);
        }
      }
      if (item.processOverview.extraTextKey) {
        if (item.processOverview.extraTextKey[language.toUpperCase()]) {
          item.extraTextKey = item.processOverview.extraTextKey[language.toUpperCase()];
        } else {
          item.extraTextKey = item.processOverview.extraTextKey;
        }
        if (typeof (item.extraTextKey) === 'object' && item.extraTextKey !== null) {
          item.extraTextKey = this.translateTextKey(item.extraTextKey, item, translations);
        }
      }
      // Customer Note Condition
      if (item.processOverview.helpTextKey && item.processOverview.helpTextKey[language.toUpperCase()]) {
        item.helpTextKey = item.processOverview.helpTextKey[language.toUpperCase()];
        item.helpTextKey = this.translateHelpTextKey(item.helpTextKey, item, translations);
        if (!item.helpTextKey) {
          item.helpTextKey = 'HIDE';
        }
      } else {
        item.helpTextKey = 'HIDE';
      }
      // Available at post office
      if (item.deliveryPoint && item.receiver.countryCode) {
        if (item.deliveryPoint.name) {
          item.pickupPointName = item.deliveryPoint.name[language.toLowerCase()];
        }
        let pickupPointAddress: string;
        if (item.deliveryPoint.street && item.deliveryPoint.streetNumber && item.deliveryPoint.postcode && item.deliveryPoint.municipality) {
          pickupPointAddress = `${item.deliveryPoint.street[language.toLowerCase()]} ${item.deliveryPoint.streetNumber[language.toLowerCase()]},
          ${item.deliveryPoint.postcode[language.toLowerCase()]} ${item.deliveryPoint.municipality[language.toLowerCase()]}`;
        }
        if (item.receiver.countryCode && item.receiver.countryCode !== 'BE') {
          if (pickupPointAddress === undefined) {
            pickupPointAddress = ` ${this.localTranslations.countries[item.receiver.countryCode]}`;
          } else {
            pickupPointAddress += ` ${this.localTranslations.countries[item.receiver.countryCode]}`;
          }
        }
        if (pickupPointAddress) {
          item.pickupPointAddress = pickupPointAddress;
        }
      }

      // Checking SafePlace Image
      item.safePlaceImgToShow = 'nosafeplace_imgtoshow';
      if (item.actualDeliveryInformation && ((item.actualDeliveryInformation.deliveryType === 'SAFEPLACE'
        || item.actualDeliveryInformation.deliveryType === 'FORCED_SAFEPLACE') && item.actualDeliveryInformation.safeplacePicture)) {
        const refID = item.actualDeliveryInformation.safeplacePicture.refId;
        this.itemsService.getImage(refID).subscribe(image => {
          // Make sure to only set images when a good response is returned (f.e. image not found or api issues, returns error object as json string)
          if (image && !image.includes('"error":')) {
            item.safePlaceImgToShow = 'data:image/jpeg;base64,' + image;
          }
        });
      } else {
        item.safePlaceImgToShow = 'nosafeplace_imgtoshow';
      }

      // Opening Hours Days Translation
      if (item.deliveryPoint && item.deliveryPoint.openingSchedules) {
        if (item.deliveryPoint.openingSchedules.length > 0) {
          if (language.toLowerCase() === 'nl') {
            item.deliveryPoint.openingSchedules[0].weekOfTheDay = 'Maandag';
            item.deliveryPoint.openingSchedules[1].weekOfTheDay = 'Dinsdag';
            item.deliveryPoint.openingSchedules[2].weekOfTheDay = 'Woensdag';
            item.deliveryPoint.openingSchedules[3].weekOfTheDay = 'Donderdag';
            item.deliveryPoint.openingSchedules[4].weekOfTheDay = 'Vrijdag';
            item.deliveryPoint.openingSchedules[5].weekOfTheDay = 'Zaterdag';
            item.deliveryPoint.openingSchedules[6].weekOfTheDay = 'Zondag';
          } else if (language.toLowerCase() === 'fr') {
            item.deliveryPoint.openingSchedules[0].weekOfTheDay = 'Lundi';
            item.deliveryPoint.openingSchedules[1].weekOfTheDay = 'Mardi';
            item.deliveryPoint.openingSchedules[2].weekOfTheDay = 'Mercredi';
            item.deliveryPoint.openingSchedules[3].weekOfTheDay = 'Jeudi';
            item.deliveryPoint.openingSchedules[4].weekOfTheDay = 'Vendredi';
            item.deliveryPoint.openingSchedules[5].weekOfTheDay = 'Samedi';
            item.deliveryPoint.openingSchedules[6].weekOfTheDay = 'Dimanche';
          } else {
            item.deliveryPoint.openingSchedules[0].weekOfTheDay = 'Monday';
            item.deliveryPoint.openingSchedules[1].weekOfTheDay = 'Tuesday';
            item.deliveryPoint.openingSchedules[2].weekOfTheDay = 'Wednesday';
            item.deliveryPoint.openingSchedules[3].weekOfTheDay = 'Thursday';
            item.deliveryPoint.openingSchedules[4].weekOfTheDay = 'Friday';
            item.deliveryPoint.openingSchedules[5].weekOfTheDay = 'Saturday';
            item.deliveryPoint.openingSchedules[6].weekOfTheDay = 'Sunday';
          }
        }
      }

      // Added on 08-JAN-2020 based on story DCS - 11592
      item.chatSupport = 'NO';
      // if (item.receiver && item.receiver.countryCode === 'BE' && (item.processOverview.activeStepTextKey.startsWith('DELIVERED')) ||
      // (item.requestedDeliveryMethod === 'HOME' && item.processOverview.activeStepTextKey.startsWith('OUT_FOR_DELIVERY') && item.attemptedDeliveryTime) ||
      // (item.requestedDeliveryMethod !== 'HOME' && item.processOverview.activeStepTextKey.startsWith('PROCESSING')))
      if (item.isChatbotVisible === true) {
        item.chatSupport = 'YES';
      } else {
        item.chatSupport = 'NO';
      }

      // TRA-1618 Show texts and icons for lean locker
      item.isLeanLockerParcel = this.getLockerDetailsLean(item);
      item.isMybpostAppLinkVisible = false;

      item.processSteps = item.processOverview.processSteps;

      item.statusArray = [];
      item.mainArray = [];
      item.returnArray = [];
      item.nameArray = [];
      item.isDeliveredStatusActive = false;
      const count = 1;
      // processSteps Loop Start
      for (const processStep of item.processSteps) {

        item.returnArray.push(processStep.label.main);

        item.statusArray.push(processStep.status);

        // Active if condition Start.
        if (processStep.status === 'active' || processStep.status === 'irregularity') {
          item.activeStep = processStep;
          this.step = item.activeStep;
          item.isDeliveredStatusActive = this.getIsDeliveredStatusActive(this.step.name);
          item.statusImageToShow = './assets/images/';
          if (tntModule === TnTModule.LMG) {
            item.statusImageToShow += 'lmg/';
          }
          if (item.productCategory !== ProductCategory.PARCEL) {
            item.statusImageToShow += 'letter/';
          }
          if (this.step.name === ActiveStep.AWAITING || this.step.name === ActiveStep.CHALLENGED) {
            item.statusImageToShow += 'customs_awaiting_payment_active.svg';
          } else if (this.step.name === ActiveStep.REFUSED || this.step.name === ActiveStep.EXPIRED) {
            item.statusImageToShow += 'return_to_sender_active_return.svg';
          } else if (this.step.name === ActiveStep.SUCCESSFUL) {
            item.statusImageToShow += 'no_payment_required_active.svg';
          } else if (this.step.name === ActiveStep.CUSTOMS) {
            item.statusImageToShow += 'customs.svg';
          } else if (this.step.name === ActiveStep.CUSTOMS_REFERENCE) {
            item.statusImageToShow += 'customs_clearance.svg';
          }
          else if (this.step.knownProcessStep === ActiveStep.AVAILABLE_IN_LEAN_LOCKER &&
            item.isLeanLockerParcel
          ) {
            // Available in Parcel Locker - Lean Locker
            item.statusImageToShow += 'leanlocker_standard_red.svg';
            item.isMybpostAppLinkVisible = true;
          }
          else if (this.step.knownProcessStep === ActiveStep.ON_THE_WAY_TO_PARCEL_LOCKER_LEAN &&
            item.isLeanLockerParcel
          ) {
            // on the way to prarcel locker - Lean locker
            item.isMybpostAppLinkVisible = true;
          }
          else if (this.step.knownProcessStep === ActiveStep.PICKEDUP_IN_LEAN_LOCKER &&
            item.isLeanLockerParcel) {
            // Picked up from Parcel Locker - Lean Locker
            item.statusImageToShow += 'leanlocker_check.svg';
          }
          else {
            item.statusImageToShow += this.step.name + (this.step.partner ? '_' + this.step.partner : '') + '_' + this.step.status + ((item.parcelReturnType === 'bts' || item.parcelReturnType === 'btsAfterRetour') && this.step.name !== 'line' ? '_return' : '') + '.svg';
          }
          if (processStep.status === 'irregularity') {
            item.irregularityToggle = true;
          }
          item.nameArray.push(processStep.name);

          if (processStep.label.main[language.toUpperCase()]) {
            item.mainArray.push(processStep.label.main[language.toUpperCase()]);
            item.activeStepMain = processStep.label.main[language.toUpperCase()].replace(/(\{\{)([\w]+)(\}\})/ig, (match, group1, group2, group3) => this.translateFinal(group2, item, translations));
          } else {
            item.mainArray.push(processStep.label.main);
            item.activeStepMain = processStep.label.main;
          }

          item.activeStepDetail = '';

          if (processStep.label.detail) {
            if (processStep.label.detail[language.toUpperCase()]) {
              item.activeStepDetail = processStep.label.detail[language.toUpperCase()].replace(/(\{\{)([\w]+)(\}\})/ig, (match, group1, group2, group3) => this.translateFinal(group2, item, translations));
            } else {
              item.activeStepDetail = processStep.label.detail;
            }
          }
          if (item.senderCommercialName !== undefined && item.activeStep.name === ActiveStep.PREPARE) {
            item.displayCommercialNameStatus = false;
            // ideally this should be true, but to accomodate EOY change for a temporary time we made it false
          } else {
            item.displayCommercialNameStatus = false;
          }

          if (item.senderCommercialName !== undefined && item.activeStep.name !== ActiveStep.PREPARE) {
            item.displayParcelByBpostStatus = true;
          } else {
            item.displayParcelByBpostStatus = false;
          }

          // Chat with support button display status.
          // commented as part of story DCS - 11592 on 08-JAN-2020.
          /*
          item.chatSupport = 'NO';
          if (item.receiver && item.receiver.countryCode === 'BE' && ((processStep.name === 'delivered' && processStep.knownProcessStep.startsWith('DELIVERED')) || (item.requestedDeliveryMethod === 'HOME' && item.processOverview.activeStepTextKey.startsWith('OUT_FOR_DELIVERY') && item.attemptedDeliveryTime))) {
            item.chatSupport = 'YES';
          } else {
            item.chatSupport = 'NO';
          } */


          // Delivery or Collected Text Info
          item.DeliveredOrCollectedText = 'Delivered To';
          item.DeliveredOrCollectedonText = 'Delivered On';
          item.DeliveredToInfo = 'HIDE';
          item.DeliveredOnInfo = 'SHOW'; // DeliveryTime Display Status
          item.showTextKey = 'PICKED_NO';
          if (item.isDeliveredStatusActive && (processStep.knownProcessStep === 'PICKED_UP_IN_POST_OFFICE' || processStep.knownProcessStep === 'PICKED_UP_IN_POST_POINT' || processStep.knownProcessStep === 'PICKED_UP_IN_KARIBOO_POINT' || processStep.knownProcessStep === 'PICKED_UP_IN_PARCEL_LOCKER' || processStep.knownProcessStep === 'PICKED_UP_IN_SHOP')) { // Collected On
            item.showTextKey = 'PICKED_YES';
            if (
              item.deliveryPoint?.type !== 'SHOP' && item.deliveryPoint?.street &&
              item.deliveryPoint?.streetNumber && item.deliveryPoint?.postcode && item.deliveryPoint?.municipality
            ) {
              if (language.toLowerCase() === 'nl') {
                item.DeliveredOrCollectedText = 'Afgehaald';
                item.DeliveredOrCollectedonText = 'Afgehaald op';
                item.DeliveredToInfo = this.getCollectedOnAddressInfo(item.deliveryPoint.street.nl, item.deliveryPoint.streetNumber.nl, item.deliveryPoint.postcode.nl, item.deliveryPoint.municipality.nl);
              } else if (language.toLowerCase() === 'fr') {
                item.DeliveredOrCollectedText = 'Enlevé';
                item.DeliveredOrCollectedonText = 'Enlevé';
                item.DeliveredToInfo = this.getCollectedOnAddressInfo(item.deliveryPoint.street.fr, item.deliveryPoint.streetNumber.fr, item.deliveryPoint.postcode.fr, item.deliveryPoint.municipality.fr);
              } else {
                item.DeliveredOrCollectedText = 'Collected';
                item.DeliveredOrCollectedonText = 'Collected On';
                item.DeliveredToInfo = this.getCollectedOnAddressInfo(item.deliveryPoint.street.en, item.deliveryPoint.streetNumber.en, item.deliveryPoint.postcode.en, item.deliveryPoint.municipality.en);
              }
            }
          } else if (
            item.isDeliveredStatusActive && processStep.knownProcessStep === 'DELIVERED_TO_SENDER' &&
            item.retourOrBackToSender
          ) { // return to Sender and time should not display
            item.DeliveredOnInfo = 'HIDE';
            if (language.toLowerCase() === 'nl') {
              item.DeliveredOrCollectedText = 'Bezorgd bij';
              item.DeliveredOrCollectedonText = 'Afgeleverd op';
            } else if (language.toLowerCase() === 'fr') {
              item.DeliveredOrCollectedText = 'Livré à';
              item.DeliveredOrCollectedonText = 'Remis le';
            } else {
              item.DeliveredOrCollectedText = 'Delivered To';
              item.DeliveredOrCollectedonText = 'Delivered On';
            }
            item.DeliveredToInfo = this.deliveryTOfullAddress(item.sender, item.sender.countryCode, '');
            if (item.DeliveredToInfo === '') {
              item.DeliveredToInfo = 'HIDE';
            }
          } else if (item.isDeliveredStatusActive) { // Delivered To
            if (language.toLowerCase() === 'nl') {
              item.DeliveredOrCollectedText = 'Bezorgd bij';
              item.DeliveredOrCollectedonText = 'Afgeleverd op';
            } else if (language.toLowerCase() === 'fr') {
              item.DeliveredOrCollectedText = 'Livré à';
              item.DeliveredOrCollectedonText = 'Remis le';
            } else {
              item.DeliveredOrCollectedText = 'Delivered To';
              item.DeliveredOrCollectedonText = 'Delivered On';
            }

            item.DeliveredToInfo = this.getDeliveriedToAddressInfo(item.actualDeliveryInformation, item.receiver);
            // if (
            //   item.requestedPreferences && item.requestedPreferences.neighbourDelivery &&
            //   item.activeStep && item.activeStep.knownProcessStep &&
            //   item.activeStep.knownProcessStep === 'DELIVERED_AT_NEIGHBOUR'
            // ) {
            //   item.DeliveredToInfo = this.getNeighbourDelivery(item.requestedPreferences.neighbourDelivery);
            // } else {
            //   item.DeliveredToInfo = this.getDeliveriedToAddressInfo(item.actualDeliveryInformation, item.receiver);
            // }
          }

          // Available Untill Logic
          item.availableUntillOrEstimatedDateStatus = '';
          item.availableFromOrEstimatedDateStatus = '';
          if ((processStep.knownProcessStep === 'AVAILABLE_IN_POST_OFFICE' || processStep.knownProcessStep === 'AVAILABLE_IN_POST_POINT' || processStep.knownProcessStep === 'AVAILABLE_IN_KARIBOO_POINT' || processStep.knownProcessStep === 'AVAILABLE_IN_PARCEL_LOCKER')) {
            if (item.latestAvailableTime && item.latestAvailableTime.day && this.expectedDeliveryDate(item.latestAvailableTime.day, this)) {
              item.latestAvailableDate2 = this.formatDate(item.latestAvailableTime.day, this);
              item.availableUntillOrEstimatedDateStatus = 'AVAILABLE_UNTIL'; // AVAILABLE_UNTIL
            }
          } else if (processStep.knownProcessStep === 'ON_THE_WAY_TO_POST_OFFICE' || processStep.knownProcessStep === 'ON_THE_WAY_TO_POST_POINT' || processStep.knownProcessStep === 'ON_THE_WAY_TO_PARCEL_LOCKER' || processStep.knownProcessStep === 'ON_THE_WAY_TO_KARIBOO_POINT' || processStep.knownProcessStep === 'ON_THE_WAY_TO_POSTAL_OFFICE' || item.processOverview.activeStepTextKey === 'PROCESSING_POST_OFFICE' || item.processOverview.activeStepTextKey === 'PROCESSING_POST_POINT' || item.processOverview.activeStepTextKey === 'PROCESSING_PARCEL_LOCKER' || item.processOverview.activeStepTextKey === 'PROCESSING_KARIBOO_POINT' ||
            item.processOverview.activeStepTextKey === 'PROCESSING_POSTAL_OFFICE' || item.processOverview.activeStepTextKey === 'PROCESSING_PARCEL_LOCKER_AVISE') {
            item.availableFromOrEstimatedDateStatus = 'AVAILABLE_FROM'; // AVAILABLE_AS_FROM
            if (item.earliestAvailableTime && item.earliestAvailableTime.day) {
              item.expectedDeliveryDate2 = this.expectedDeliveryDate(item.earliestAvailableTime.day, this);
              if (item.expectedDeliveryDate2 && item.earliestAvailableTime.time) {
                item.expectedDeliveryDate2 = item.expectedDeliveryDate2 + ',';
                item.availableFromTime = item.earliestAvailableTime.time;
              }
            }
            /* Commented as per Story DCS-11276 */
            /* else if (item.expectedDeliveryTimeRange && item.expectedDeliveryTimeRange.day) {
              item.expectedDeliveryDate2 = this.expectedDeliveryDate(item.expectedDeliveryTimeRange.day, this);
              item.availableFromTime = this.availableFromTimeBackup;
            } */
          } else if (item.expectedDeliveryTimeRange && item.expectedDeliveryTimeRange.day) {
            if (processStep.knownProcessStep === 'IN_PREPARATION' && item.expectedDeliveryTimeRange.day2) {
              item.expectedDeliveryDate2 = this.expectedDeliveryDatePrepration(item.expectedDeliveryTimeRange.day, this) + ' - ' + this.expectedDeliveryDate(item.expectedDeliveryTimeRange.day2, this);
            }
            else {
              item.expectedDeliveryDate2 = this.expectedDeliveryDate(item.expectedDeliveryTimeRange.day, this);
            }
            item.availableFromOrEstimatedDateStatus = 'ESTIMATED'; // ESTIMATED_DELIVERY_DATE
          }

        } // // Active if condition End.
      } // processSteps Loop End.
      /* Added this as part of DSC-10563, Date: 07-JAN-2020 */
      if (!item.DeliveredToInfo) {
        item.DeliveredToInfo = 'NOADDRESS';
      }

      item.headings = new Object();
      item.commercialSender = translations.commercial_sender.text.replace(/(\{\{)([\w]+)(\}\})/ig, (match, group1, group2, group3) => this.translateFinal(group2, item, translations));

      item.headings.barcode = translations.Items_summary.parcel_number.replace(/(\{\{)([\w]+)(\}\})/ig, (match, group1, group2, group3) => this.translateFinal(group2, item, translations));
      item.headings.parcel_details = translations.parcel_details.parcel_details.replace(/(\{\{)([\w]+)(\}\})/ig, (match, group1, group2, group3) => this.translateFinal(group2, item, translations));

      item.headings.more_info_about_parcel = translations.parcel_details.more_info_about_parcel.replace(/(\{\{)([\w]+)(\}\})/ig, (match, group1, group2, group3) => this.translateFinal(group2, item, translations));
      item.headings.items_faq_heading = translations.items_FAQs.items_faq_heading.replace(/(\{\{)([\w]+)(\}\})/ig, (match, group1, group2, group3) => this.translateFinal(group2, item, translations));
      item.headings.TNT_activity_sub_heading = translations.TNT_activity.TNT_activity_sub_heading.replace(/(\{\{)([\w]+)(\}\})/ig, (match, group1, group2, group3) => this.translateFinal(group2, item, translations));

      if (item.actualDeliveryInformation && item.actualDeliveryInformation.actualDeliveryTime) {
        item.actualDeliveryDate = this.formatDate(item.actualDeliveryInformation.actualDeliveryTime.day, this);
      }
      if (item.comments && item.comments.Comment1 && item.comments.Comment1 !== '') {
        item.comments = item.comments.Comment1;
      }
    } // Item loop End
    sessionStorage.setItem('tntModule', tntModule);
    return items;
  }

  getLockerDetailsLean(item) {
    if (item.lockerDetails && item.lockerDetails.lockerType && item.lockerDetails.lockerType === 'Lean Locker') {
      return true;
    }
    return false;
  }

  getCollectedOnAddressInfo(street, streetNumber, postcode, municipality) {
  }

  getDeliveriedToAddressInfo(actualDeliveryInformation, receiver) {
    let DeliveredToInfo = 'HIDE';
    if (actualDeliveryInformation && actualDeliveryInformation.address && receiver.countryCode) {
      const addressInfo = actualDeliveryInformation.address;
      DeliveredToInfo = this.deliveryTOfullAddress(addressInfo, receiver.countryCode, 'AD');
    } else if (receiver && receiver.countryCode) {
      const addressInfo = receiver;
      DeliveredToInfo = this.deliveryTOfullAddress(addressInfo, receiver.countryCode, '');
    }
    if (DeliveredToInfo === '') {
      DeliveredToInfo = 'HIDE';
    }
    return DeliveredToInfo;
  }

  // TRA-800 change
  getIsDeliveredStatusActive(name: string) {
    return name.includes('delivered');
  }

  deliveryTOfullAddress(address, countryCode, type) {
    let beCountry = false;
    let output = '';

    if (countryCode === 'BE') {
      countryCode = '';
      beCountry = true;
    } else {
      countryCode = this.localTranslations.countries[countryCode];
    }

    if (type === 'AD') {
      if (address.StreetName) {
        output += address.StreetName;
        if (address.StreetNumber) {
          output += ' ' + address.StreetNumber;
        }
      }
      if (address.StreetNumberAddition && address.StreetName && address.StreetNumber) {
        output += ' / ' + address.StreetNumberAddition;
      }
      if (address.StreetName) {
        output += ', ';
      }
      if (address.PostalCode) {
        output += address.PostalCode + ' ';
      }
      if (address.City) {
        output += address.City + ', ';
      }
      if (countryCode) {
        output += countryCode;
      }
      if (!countryCode) {
        output = output.trim().replace(', ', '<sub>').replace(',', '').replace('<sub>', ', ');
      }
    } else {
      if (address.street) {
        output += address.street;
      }
      if (address.box && address.street) {
        output += ' / ' + address.box;
      }
      if (address.street) {
        output += ', ';
      }
      if (address.municipality) {
        if (address.postcode) {
          output += address.postcode + ' ';
        }
        output += address.municipality + ', ';
      }
      if (countryCode) {
        output += countryCode;
      }
      if (!countryCode) {
        output = output.trim().replace(', ', '<sub>').replace(',', '').replace('<sub>', ', ');
      }
    }

    // display only country code is available.
    if (output === '' && beCountry) {
      output = this.localTranslations.countries.BE;
    }
    output = output.toUpperCase();
    return output;
  }

  formatDate(date, context) {
    const today = new Date();
    const givenDay = new Date(date);
    const dateLocale = moment(givenDay);
    if (today.toDateString() === givenDay.toDateString()) {
      return context.localTranslations.label.today;
    }
    return dateLocale.locale(localStorage.getItem('lang')).format('dddd D MMMM');
  }
  formatDatewithYear(date, context) {
    const today = new Date();
    const givenDay = new Date(date);
    const dateLocale = moment(givenDay);
    if (today.toDateString() === givenDay.toDateString()) {
      return context.localTranslations.label.today;
    }
    return dateLocale.locale(localStorage.getItem('lang')).format('dddd D MMMM YYYY');
  }
  expectedDeliveryDate(date, context) {
    const today = new Date();
    const givenDay = new Date(date);
    const dateLocale = moment(givenDay);
    if (today.toDateString() === givenDay.toDateString()) {
      return context.localTranslations.label.today;
    }
    if (dateLocale.diff(today, 'days') < 0) {
      return undefined;
    }
    return dateLocale.locale(localStorage.getItem('lang')).format('dddd D MMMM');
  }
  expectedDeliveryDateTimeRangeFun(dateRange, context) {
    const today = new Date();
    const givenDay = new Date(dateRange.day);
    const dateLocale = moment(givenDay);
    const givenDay2 = new Date(dateRange.day2);
    const dateLocale2 = moment(givenDay2);

    /** if upper bound is today then returns today in long copy */
    if (today.toDateString() === givenDay2.toDateString()) {
      return context.localTranslations.label.today;
    }

    return context.localTranslations.label.datePrepositionBetween + ' ' +
      dateLocale.locale(localStorage.getItem('lang')).format('dddd D MMMM') + ' ' +
      context.localTranslations.label.datePrepositionAnd + ' ' +
      dateLocale2.locale(localStorage.getItem('lang')).format('dddd D MMMM');
  }
  expectedDeliveryDatePrepration(date, context) {
    const today = new Date();
    const givenDay = new Date(date);
    const dateLocale = moment(givenDay);
    if (today.toDateString() === givenDay.toDateString()) {
      return context.localTranslations.label.today;
    }
    return dateLocale.locale(localStorage.getItem('lang')).format('dddd D MMMM');
  }
  addDatePreposition(formattedDate, context) {
    if (formattedDate !== context.localTranslations.label.today) {
      formattedDate = context.localTranslations.label.datePreposition + ' ' + formattedDate;
    }
    return formattedDate;
  }
  addDatePrepositionFrench(formattedDate, context) {
    if (localStorage.getItem('lang') === 'fr' && formattedDate !== context.localTranslations.label.today) {
      formattedDate = context.localTranslations.label.datePreposition + ' ' + formattedDate;
    }
    return formattedDate;
  }
  formLeadTimeText(leadTimeRange, context) {
    if (leadTimeRange.minDays === leadTimeRange.maxDays) {
      if (leadTimeRange.minDays === 1) {
        return context.localTranslations.label.LeadTimeDay;
      }
      return context.localTranslations.label.LeadTimeDays.replace('{{days}}', leadTimeRange.minDays);
    } else {
      return context.localTranslations.label.LeadTimeDayRange.replace('{{minDays}}', leadTimeRange.minDays).replace('{{maxDays}}', leadTimeRange.maxDays);
    }
  }

  formURL(url) {
    const lang = localStorage.getItem('lang') || 'fr';
    return url[lang];
  }

  infoAboutShipmentURL(itemCode, context) {
    return context.localTranslations.label.infoAboutShipmentURL + itemCode;
  }

  /*
  fullAddress(address, countryCode) {
    let beCountry = false;
    if (countryCode == 'BE') {
      countryCode = '';
      beCountry = true;
    }
    else {
      countryCode = this.localTranslations.countries[countryCode];
    }
    let output = '';
      if (address.street) {
        output += address.street + ', '
      }
      if (address.municipality) {
        if (address.postcode) {
          output += address.postcode + ' '
        }
        output += address.municipality + ', '
      }
      if (countryCode)
        output += countryCode
      if (!countryCode) {
        output = output.trim().replace(', ', '<sub>').replace(',', '').replace('<sub>', ', ');
      }
          // display only country code is available.
      if(output === '' && beCountry){
        output = this.localTranslations.countries['BE'];
      }
    return output;
  } */

  showLeadTimesLink(showLink) {
    if (showLink) {
      return true;
    }
    return undefined;
  }
  currencify(amount) {
    amount = parseFloat(amount);
    if (amount === 0) {
      return undefined;
    }
    return '&euro;' + amount;
  }
  toUpperCase(value) {
    return value[0].toUpperCase() + value.slice(1);
  }
  getLocation(obj) {
    return obj[localStorage.getItem('lang')];
  }
  getSender(value, context) {
    if (value === 'default') { return context.localTranslations.sender; }
    return value;
  }
  getthisSender(value, context) {
    if (value === 'default') { return context.localTranslations.Sender; }
    return value;
  }
  translateReason(reasonCode, context) {
    return context.localTranslations.reason[reasonCode];
  }
  isRegisteredMail(productCategory, context) {
    if (productCategory === 'registeredLetter') {
      return context.localTranslations.productCategory.registeredLetter.name;
    } else {
      return undefined;
    }
  }
  translateFinal(val, items, translations) {
    const source = { items, translations };
    const instructions = this.template2[val];
    let output = '';
    let path;
    for (let i = 0; i < instructions.length; i += 2) {
      if (output === undefined && val !== 'country') {
        break;
      }

      path = instructions[i + 1];
      if (output !== '') {
        if (instructions[i] === 'function') {
          output = path(output, this);
        } else {
          path = path.replace('$$', output);
        }
      }
      if (instructions[i] !== 'function') {
        output = this.traversePath(source[instructions[i]], path);
      }
    }

    return output;

  }
  traversePath(obj, path) {
    let breadcrumbs;
    breadcrumbs = path.split('.');
    for (const bc of breadcrumbs) {
      if (obj === undefined || bc === 'default') {
        break;
      }
      obj = obj[bc];
      if (breadcrumbs[breadcrumbs.length - 1] === 'default' && obj === undefined) {
        obj = breadcrumbs[breadcrumbs.length - 1];
        break;
      }
    }
    return obj;
  }

  translateTextKey(textKey, item, translations) {
    let output = '';
    let outputTranslated = '';
    for (const key of Object.keys(textKey)) {
      if (!Number.isNaN(Number(key))) {
        outputTranslated = textKey[key].replace(/(\{\{)([\w]+)(\}\})/ig, (match, group1, group2, group3) => this.translateFinal(group2, item, translations));
        if (!outputTranslated.includes('undefined')) {
          if (item.hasOwnProperty('reasonCode') && item.reasonCode === 'undelivered.present-again.next-day-missed_special_delivery_volume') {
            outputTranslated = outputTranslated.replace(/(\{\{)([\w]+)(\}\})/ig, (match, group1, group2, group3) => this.translateFinal(group2, item, translations));
          }
          output += outputTranslated + ' ';
        }
      } else {
        break;
      }
    }
    // return output.replace("  ", " ").replace(" .", ".").replace("..", ".").trimRight();
    return output.replace(/\s\s/g, ' ').replace(/\s\./g, '.').replace(/\.\./g, '.').replace(/\s+$/, '');
  }

  translateHelpTextKey(helpTextKey, item, translations) {
    let output = '';
    let outputTranslated = '';
    let count = 0;
    for (const key of Object.keys(helpTextKey)) {
      if (!Number.isNaN(Number(key))) {
        outputTranslated = helpTextKey[key].replace(/(\{\{)([\w]+)(\}\})/ig, (match, group1, group2, group3) => this.translateFinal(group2, item, translations));
        if (!outputTranslated.includes('undefined')) {
          output += outputTranslated + ' ';
          if (Number(key) === 1) {
            count++;
          } else if (count > 0) {
            count++;
          }
        }
      } else {
        break;
      }
    }
    if (count < 2) { // Count will be atleast 2 if we have two lines of text under help text key, 1 question and 2 answer. If its only 1 its understood that only question is there in the helpTextKey
      return undefined;
    }
    return output.replace('  ', ' ').replace(' .', '.').replace('..', '.').replace(/\s+$/, ''); // last regexp replace used to trim right spaces
  }

  backToHome(module = TnTModule.BTR) {
    const homeRoute = module === TnTModule.LMG ? 'lmg/home' : 'home';
    this.router.navigate([homeRoute], {
      queryParams: { lang: localStorage.getItem('lang') }
    });
  }

  setLocalStorage(lang) {
    if (lang === 'en' || lang === 'fr' || lang === 'nl') {
      this.translate.setDefaultLang(lang);
      localStorage.setItem('lang', lang);
      localStorage.setItem('preferedLanKey', lang);
      return lang;
      // this.lang = lang;
    } else {
      this.translate.setDefaultLang('fr');
      localStorage.setItem('lang', 'fr');
      localStorage.setItem('preferedLanKey', 'fr');
      return localStorage.getItem('lang');
      // this.lang = 'fr';
    }
  }

  public getProductCategoryName(item: any): string {
    return this.localTranslations.productCategory[item.productCategory].name;
  }

  public doesProcessStepsContainsPaymentDone(singleItem) {
    return (singleItem.customsPaymentStatus && singleItem.customsPaymentStatus === 'PAID');
  }

  public getCountryName(countryCode): string {
    return this.localTranslations.countries[countryCode];
  }
}
